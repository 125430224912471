import React from "react";
import styled from "styled-components";

const AboutContainer = styled.section`
  margin: 2rem 0;
  animation: fadeIn 2s;
  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  span {
    color: var(--accent-color);
    text-transform: uppercase;
  }
  .about__tech,
  .about__fun {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 642px) {
    margin: 4rem 0 2rem 0;
  }
`;

const TechButton = styled.div`
  background: var(--accent-bg-color);
  width: max-content;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: bold;
  margin: 0.25rem;
`;

const About = () => {
  const techArray = [
    "Node.js",
    "ReactJS",
    "Next.js",
    "GraphQL",
    "JavaScript",
    "TypeScript",
    "Java",
    "Python",
  ];

  return (
    <AboutContainer id="about">
      <h2>About Me</h2>
      <p>
        Hello, my name is Utkarsh <span>(Uu-t-k-uh-r-sh)</span>, I like design, develop, 
        and maintain software applications that solve real-world problems. 
        With expertise in various programming languages and frameworks, 
        I strive to write clean, efficient, and scalable code that meets 
        the needs of end-users. I enjoy collaborating with cross-functional
        teams to brainstorm innovative solutions and continuously improve software performance.
      </p>

      <p>Here are some technologies I'm currently working with:</p>
      <div className="about__tech">
        {techArray.map((item) => (
          <TechButton key={item}>{item}</TechButton>
        ))}
      </div>
    </AboutContainer>

  );
};

export default About;
